<template>
  <div class="main-content">
    <b-overlay :show="showOverlay" opacity="0.60" rounded="sm" variant='white' no-wrap></b-overlay>
    <h2 class="mb-4">{{ mode === 'add' ? 'Add Customer' : 'Edit Customer'}}</h2>
    <!-- <div class="wrapper"> -->
    <b-row>
      <b-col md="12 mb-30">
        <b-card>
          <!-- <b-form @submit.prevent="submit" novalidate> -->
            <b-row>
              <b-form-group
                class="col-md-6 mb-3"
                label="Customer Name"
                label-for="customerName"
                :class="{ 'form-group--error': $v.form.customerName.$error }"
              >
                <b-form-input
                  v-model="form.customerName"
                  type="text"
                  required
                  placeholder="Customer Name"
                  size="lg"
                ></b-form-input>
                <div class="error" v-if="$v.form.customerName.$error">
                  Field is required
                </div>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Contact Person"
                label-for="contactPerson"
              >
                <b-form-input
                  v-model="form.contactPerson"
                  type="text"
                  required
                  placeholder="Contact Person"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Email"
                label-for="email"
                :class="{ 'form-group--error': $v.form.customerName.$error }"
              >
                <b-form-input
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Email"
                  size="lg"
                ></b-form-input>
                <div class="error" v-if="$v.form.email.$error">
                  Field is required
                </div>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Mobile Number"
                label-for="mobileNo"
              >
                <b-form-input
                  v-model="form.mobileNo"
                  type="text"
                  required
                  placeholder="Mobile Number"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Phone Number"
                label-for="phoneNo"
              >
                <b-form-input
                  v-model="form.phoneNo"
                  type="text"
                  required
                  placeholder="Phone Number"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Website"
                label-for="website"
              >
                <b-form-input
                  v-model="form.website"
                  type="text"
                  required
                  placeholder="Website"
                  size="lg"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 mb-3"
                label="Address"
                label-for="input-1"
              >
                <b-form-textarea
                  v-model="form.address"
                  required
                  placeholder="Address"
                  size="lg"
                  rows="4"
                ></b-form-textarea>
                <!-- <div class="error" v-if="$v.form.customerAddress.address">
                  Field is required
                </div> -->
              </b-form-group>

              <div class="col-md-6">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <b-form-select
                    v-model="form.countryName"
                    id="inline-form-custom-select-pref1"
                    size="lg"
                    @change="countryChanged()"
                  >
                    <b-form-select-option
                      :value="country"
                      v-for="(country, index) in countries"
                      :key="index"
                      >{{ country.countryName }}</b-form-select-option
                    >
                  </b-form-select>
                  <!-- <div class="error" v-if="$v.form.customerAddress.countryName">
                    Field is required
                  </div> -->
                </b-form-group>

                <b-form-group
                  class="mb-3"
                  label="City"
                  label-for="city"
                >
                  <b-form-select
                    v-model="form.cityName"
                    id="inline-form-custom-select-pref1"
                    size="lg"
                    v-if="city.length > 0"
                  >
                    <b-form-select-option
                      :value="c"
                      v-for="(c, index) in city"
                      :key="index"
                      >{{ c }}</b-form-select-option
                    >
                  </b-form-select>
                  <b-form-input
                    v-model="form.cityName"
                    type="text"
                    required
                    size="lg"
                    v-else
                  ></b-form-input>
                  <!-- <div class="error" v-if="$v.form.customerAddress.cityName">
                    Field is required
                  </div> -->
                </b-form-group>
              </div>
            </b-row>

            <b-row>
              <b-form-group
                class="col-md-6 mb-3"
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  v-model="form.description"
                  required
                  placeholder="Description"
                  size="lg"
                  rows="4"
                ></b-form-textarea>
              </b-form-group>

              <div class="col-md-6 mb-3">
                <b-form-group
                  class="mb-3"
                  label="Upload Logo"
                  label-for="logo"
                >
                  <b-form-file
                    v-model="form.logo"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="uploadLogo"
                  ></b-form-file>

                  <div v-if="mode === 'edit'">
                    <img class="img-thumbnail p-2 mt-3" :src="getFullImageUrl(form.productUrl)" alt="Image preview"/>
                  </div>
                </b-form-group>
              </div>
            </b-row>

            <b-row>
              <b-col md="12" class="d-flex justify-content-end">
                <b-button
                  class="mt-3"
                  variant="primary"
                  @click="submit"
                  >Submit</b-button
                >
              </b-col>
            </b-row>
          <!-- </b-form> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Add Customer",
  },
  data() {
    return {
      showOverlay: false,
      form: {
        id: null,
        customerName: "",
        contactPerson: "",
        email: "",
        mobileNo: "",
        phoneNo: "",
        website: "",
        description: "",
        url: "",
        isVarified: true,
        isUnsubscribe: false,
        address: "",
        countryName: "",
        cityName: "",
        countryId: "",
        cityId: "",
        logo: "",
        isImageUpload: false,
      },
      mode: "add"
    };
  },
  validations: {
    form: {
      customerName: {
        required,
      },
      email: {
        required,
      },
      // customerAddress: {
      //   address: {
      //     required,
      //   },
      //   countryName: {
      //     required,
      //   },
      //   cityName: {
      //     required,
      //   },
      // },
      // billingAddress: {
      //   address: {
      //     required,
      //   },
      //   countryName: {
      //     required,
      //   },
      //   cityName: {
      //     required,
      //   },
      // },
      // shippingAddress: {
      //   address: {
      //     required,
      //   },
      //   countryName: {
      //     required,
      //   },
      //   cityName: {
      //     required,
      //   },
      // },
    },
  },
  computed: {
    ...mapGetters(["countries", "city"]),
    customerId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions(["getCustomer", "getCountries", "getCity", "addCustomer"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Please fill required fields', {
            title: 'Error',
            variant: "danger",
            autoHideDelay: 5000,
          })
      } else {
        this.showOverlay = true;
        this.form.countryName = this.form.countryName.countryName
        if(this.form.logo) {
          this.form.isImageUpload = true
        } else {
          this.form.isImageUpload = false
        }

        if (this.customerId) {
          this.updateCustomer(this.form).then(c => {
            this.showOverlay = false;
            this.$bvToast.toast("Customer updated successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            })
            this.$router.push('/customer')
          })
          .catch(err => {
            this.showOverlay = false;
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            })
          })
        } else {
          this.addCustomer(this.form).then(c => {
            this.showOverlay = false;
            this.$bvToast.toast("Customer added successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            })
            this.$router.push('/customer')
          })
          .catch(err => {
            this.showOverlay = false;
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            })
          })
        }
      }
    },
    countryChanged() {
      this.getCity(this.form.countryName.countryName);
    },
    uploadLogo() {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        vm.form.logo = reader.result
      };
    },
    getFullImageUrl(path) {
      if (path) {
        // Replace backslashes with forward slashes and add "Thumbnail" after "ProductImages"
        path = path.replace(/\\/g, '/').replace('ProductImages/', 'ProductImages/Thumbnail/');
        // Ensure there's no duplicate slash
        if (this.imageBaseUrl.endsWith('/') && path.startsWith('/')) {
          path = path.slice(1);
        } else if (!this.imageBaseUrl.endsWith('/') && !path.startsWith('/')) {
          path = '/' + path;
        }
        return this.imageBaseUrl + path;
      }
      return '';
    }
  },
  async mounted() {
    this.getCountries();

    if (this.customerId) {
      this.mode = "edit"
      const currentCustomer = await this.getCustomer(this.customerId);
      this.form = {...this.form, ...currentCustomer}
    }
  },
};
</script>

<style scoped>
.form-group label {
  font-size: 14px !important;
}
.form-control {
  background: #ffffff;
}
</style>
